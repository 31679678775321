var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.rendered)?_c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:({
		handler: _vm.onIntersect,
		options: { rootMargin: '-49.95% 0% -49.95% 0%' },
	}),expression:"{\n\t\thandler: onIntersect,\n\t\toptions: { rootMargin: '-49.95% 0% -49.95% 0%' },\n\t}"}],ref:"wrapper",class:("panel background-" + _vm.background + " color-" + _vm.color + " template-" + _vm.template),style:({
		'padding-left': _vm.padding,
		'padding-right': _vm.padding,
		'background-image': 'url(' + _vm.image + ')',
	}),attrs:{"id":'panel-' + _vm.sanitizeTitle(_vm.section_name),"data-section-name":_vm.section_name}},[(_vm.block.attrs.template == 'wave-top' || _vm.template == 'image')?_c('Wave',{attrs:{"color":"#fff","position":"top"}}):_vm._e(),_c('div',[_c('MainColumn',[(_vm.block.attrs.name || (_vm.block.attrs.button_url && _vm.block.attrs.template != 'image'))?_c('header',{staticClass:"panel-header"},[_c('div',{staticClass:"title-wrap"},[(_vm.block.attrs.name)?_c('h2',{staticClass:"font-size-35"},[_vm._v(" "+_vm._s(_vm.block.attrs.name)+" ")]):_vm._e()]),_c('div',{staticClass:"button-wrap"},[(_vm.block.attrs.button_label && _vm.block.attrs.button_url)?_c('Button',{attrs:{"color":_vm.color,"href":_vm.block.attrs.button_url,"value":_vm.block.attrs.button_label ? _vm.block.attrs.button_label : _vm.block.attrs}}):_vm._e()],1)]):_vm._e()]),(_vm.block.innerBlocks !== undefined && _vm.template != 'image')?_c('v-row',{attrs:{"no-gutters":""}},[(_vm.template != 'fullscreen')?_c('MainColumn',[_c('RenderBlocks',{attrs:{"blocks":_vm.block.innerBlocks,"parentBlock":_vm.block}})],1):_c('RenderBlocks',{attrs:{"blocks":_vm.block.innerBlocks,"parentBlock":_vm.block}})],1):_vm._e(),(_vm.template == 'wave-bottom')?_c('Wave',{attrs:{"color":_vm.getHexColor(_vm.background),"position":"bottom"}}):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }