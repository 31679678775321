<template>
	<div class="aili-products">
		<div class="buttons-type" v-if="descriptions">
			<div
				class="button-lactose"
				:class="{
					active: active_filter == 'naturally_lactose_free' || !active_filter,
				}"
				@click="updateProducts('naturally_lactose_free')"
			>
				<div class="lactose-icon naturally_lactose_free" />
				<br class="clear" />
				<div class="mx-4" v-html="descriptions.naturally_lactose_free.replaceAll('\\', '')" />
			</div>
			<div
				:class="{
					'button-lactose': true,
					active: active_filter == 'milk_free' || !active_filter,
				}"
				@click="updateProducts('milk_free')"
			>
				<div class="lactose-icon milk_free" />
				<br class="clear" />
				<div class="mx-4" v-html="descriptions.milk_free.replaceAll('\\', '')" />
			</div>
			<div
				:class="{
					'button-lactose': true,
					active: active_filter == 'lactose_free' || !active_filter,
				}"
				@click="updateProducts('lactose_free')"
			>
				<div class="lactose-icon lactose_free" />
				<br class="clear" />
				<div class="mx-4" v-html="descriptions.lactose_free.replaceAll('\\', '')" />
			</div>
		</div>
		<v-fade-transition>
			<v-expansion-panels accordion v-if="!loadingProducts">
				<v-expansion-panel v-for="(category, i) in products" :key="i">
					<v-expansion-panel-header
						disable-icon-rotate
						class="exp-panel"
						color="#fff"
						v-if="category.category"
					>
						<div class="category-image" :style="'background-image: url(' + category.image + ')'" />
						<div class="category-info">
							<p class="font-size-20 category-title">
								{{ category.category.name }}
							</p>
							<p
								class="font-size-14 color-gray category-description"
								v-html="category.category.description"
							/>
						</div>
						<template v-slot:actions>
							<v-icon class="icon-plus" :color="pageColor">
								mdi-plus
							</v-icon>
							<v-icon class="icon-minus" :color="pageColor">
								mdi-minus
							</v-icon>
						</template>
					</v-expansion-panel-header>
					<v-expansion-panel-content class="exp-panel" v-if="category.category">
						<div
							v-if="getCategoriesNumber(category.products) === 0"
							class="product pl-1"
							:class="{ 'text-center': $vuetify.breakpoint.mobile }"
						>
							Nessun prodotto disponibile
						</div>
						<div
							v-else
							v-for="(product, j) in category.products"
							:key="j"
							class="product"
							:class="{
								active: product.show,
							}"
						>
							<div v-if="product.show">
								<div :class="'lactose-icon small ' + product.qzr_aili_product_type"></div>
								<p
									v-html="
										product.title.rendered +
											(product['aili-product-brand_data'][0]
												? ' ' + product['aili-product-brand_data'][0].name
												: '')
									"
								/>
							</div>
						</div>
					</v-expansion-panel-content>
				</v-expansion-panel>
			</v-expansion-panels>
		</v-fade-transition>
	</div>
</template>

<script>
export default {
	props: ['block', 'blocks', 'parentBlock'],
	components: {},
	data() {
		return {
			active_filter: false,
			descriptions: false,
			loadingProducts: false,
			order_products_type: {
				naturally_lactose_free: 0,
				milk_free: 1,
				lactose_free: 2,
			},
			products: {},
		};
	},
	async mounted() {
		this.loadingProducts = true;
		let maxPage = await this.getProducts(1);

		for (let i = 2; i <= maxPage; i++) {
			await this.getProducts(i);
		}
		this.sortByProductType();
		this.loadingProducts = false;
	},
	methods: {
		getCategoriesNumber(products) {
			let visibleProductsNumber = 0;
			products.map((product) => {
				if (product.show) {
					visibleProductsNumber++;
				}
			});
			return visibleProductsNumber;
		},
		async getProducts(page) {
			let response_products = await this.$api.get('wp/v2/aili-product', {
				params: {
					per_page: 100,
					page: page,
				},
			});
			let products_data = response_products.data;
			this.descriptions = products_data[0].qzr_type_descriptions;

			let category;
			products_data.map((product) => {
				category = product['aili-product-category_data'][0]
					? product['aili-product-category_data'][0]
					: {
							name: 'Senza categoria',
							description: 'Senza categoria',
					  };
				product.show = true;
				if (this.products[category.term_order]) {
					this.products[category.term_order].products.push(product);
				} else {
					this.products[category.term_order] = {};
					this.products[category.term_order]['products'] = [product];
					this.products[category.term_order]['category'] = category;
					this.products[category.term_order]['image'] = product.qzr_image_category;
				}
			});

			return response_products.headers['x-wp-totalpages'];
		},
		isOverflowing() {
			var element = this.$refs.cartList;
			return element.offsetHeight < element.scrollHeight || element.offsetWidth < element.scrollWidth;
		},
		updateProducts(type = false) {
			if (this.active_filter == type) {
				this.active_filter = false;
				for (let category in this.products) {
					for (let product in this.products[category].products) {
						this.products[category].products[product].show = true;
					}
				}
			} else {
				this.active_filter = type;
				for (let category in this.products) {
					for (let product in this.products[category].products) {
						if (type) {
							if (this.products[category].products[product].qzr_aili_product_type == type) {
								this.products[category].products[product].show = true;
							} else {
								this.products[category].products[product].show = false;
							}
						} else {
							this.products[category].products[product].show = true;
						}
					}
				}
				console.log(this.products);
			}
		},
		sortByProductType() {
			let products_keys = Object.keys(this.products);
			for (var i = 0; i < products_keys.length; i++) {
				let products = this.products[products_keys[i]].products;
				let sorted_products = products.sort((a, b) => {
					let product_type_order =
						this.order_products_type[a['qzr_aili_product_type']] -
						this.order_products_type[b['qzr_aili_product_type']];
					if (product_type_order === 0) {
						if (a.title.rendered < b.title.rendered) {
							return -1;
						}
						if (a.title.rendered > b.title.rendered) {
							return 1;
						}
						return 0;
					} else {
						return product_type_order;
					}
				});
				this.products[products_keys[i]].products = sorted_products;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.category-title {
  margin-bottom: 10px;
  @media (max-width: $mobile-m) {
    margin-bottom: 5px;
  }
}
.category-description {
  padding-right: 25px;
  margin-bottom: 5px;
}
.buttons-type {
  display: inline-flex;
  justify-content: space-around;
  width: 100%;
  padding: $spacing-0 0;
  @media (max-width: $tablet-m) {
    display: inline-block;
    border-bottom: 1px solid;
  }
}
.v-expansion-panel::after {
  border-color: #ff8500 !important;
}
.category-image {
  float: left;
  width: 60px;
  max-width: 60px;
  height: 60px;
  margin-right: $spacing-0;
  background-color: #d5d5d5;
  background-position: center center;
  background-size: cover;
  border-radius: 100%;

  @media (max-width: $mobile-m) {
    flex-shrink: 0;
    margin-right: 10px;
  }
}
.category-info {
  display: inline-block;
}
.button-lactose {
  position: relative;
  flex-shrink: 0;
  max-width: 33%;
  transition: opacity .3s;
  cursor: pointer;
  opacity: .3;

  @media (max-width: $tablet-m) {
    display: inline-flex;
    width: 100%;
    max-width: 100%;
  }

  &.active {
    opacity: 1;
  }
}

.v-expansion-panel::before {
  box-shadow: none !important;
}
.v-expansion-panel {
  box-shadow: 0 -30px 15px -10px white;
}

.v-expansion-panel-content {
  overflow-y: scroll;
  max-height: 40vh;

  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
  &__wrap:last-child {
    padding-bottom: 20px;
  }
}
.exp-panel p {
  width: 100%;
}
.icon-plus,
.icon-minus {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  transition: opacity .3s;
}
.icon-minus {
  opacity: 0;
}
.v-expansion-panel-header--active .icon-minus {
  opacity: 1;
}

.v-expansion-panel-header--active .icon-plus {
  opacity: 0;
}
.v-expansion-panel-header {
  @media (max-width: $mobile-m) {
    padding: 16px 0;
  }
}

.lactose-icon {
  width: 150px;
  height: 150px;
  margin: auto;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background-size: 100% 100% !important;

  &.lactose_free {
    background: url("../../../assets/lactose_free.svg");
  }
  &.milk_free {
    background: url("../../../assets/milk_free.svg");
  }
  &.naturally_lactose_free {
    background: url("../../../assets/naturally_lactose_free.svg");
  }

  @media (max-width: $tablet-m) {
    position: relative;
    width: 70px;
    min-width: 70px;
    height: 70px;
    margin: 0;
  }
}

.product {
  position: relative;
  float: right;
  overflow: hidden;
  width: calc(100% - 80px);
  min-height: 40px;
  line-height: 36px;
  transition: max-height .6s;

  @media (max-width: $tablet-m) {
    width: 100%;
  }
  @media (max-width: $tablet-m) {
    height: auto;
    min-height: 40px;
    padding-top: 10px;
    line-height: 20px;
  }

  &:not(.active) {
    min-height: 0;
  }
  &.active {
    max-height: 100px;
    border-top: 1px solid #d8d8d8;
  }

  .lactose-icon {
    &.small {
      position: absolute;
      top: 0;
      left: 0;
      width: 36px;
      min-width: 36px;
      height: 36px;
      background-size: 100% 100%;

      @media (max-width: $mobile-m) {
        top: 5px;
      }
    }
  }

  p {
    padding-left: 40px;
    margin-bottom: 0;

    @media (max-width: $mobile-m) {
      margin-bottom: 10px;
    }
  }

  &:last-child {
    padding-bottom: 30px;
  }
}

.color-gray {
  color: #999;
}
</style>
